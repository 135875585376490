<template>
  <div class="common_formInfo_static">
    <div class="item" v-for="(item, index) in dataList" :key="index">
      <div v-if="item.title" class="top">
        <div><span></span> {{ item.title }}</div>
      </div>
      <template v-if="item.type == 'table'">
        <a-table :data="tableData" :bordered="{wrapper: true, cell: true}" :pagination="false">
          <template #columns>
            <a-table-column 
              v-for="(line, index) in item.children" 
              :key="index" 
              :title="line.name" 
              :width="line.width" 
              :stripe="true"
              :align="line.align || 'left'"
              :data-index="line.key"> 
              <template v-if="line.type == 'index'" #cell="{ rowIndex }">{{ rowIndex + 1 }}</template>
            </a-table-column>
          </template>
        </a-table>
      </template>
      <template v-else>
        <a-descriptions :data="item.children" align="left" :column="item.flex">
          <a-descriptions-item v-for="line in item.children" :key="line.key" >
            <template #label>
              <svg-icon v-if="line.icon" :icon-class="line.icon"></svg-icon> {{ line.name }}
            </template>
            <template v-if="line.type == 2">
              {{ detail[line.key[0]] }}&nbsp;至&nbsp;{{ detail[line.key[1]] }}&nbsp;&nbsp;&nbsp;(共 {{ detail.courseTotal }} 天)
            </template>
            <template v-else>{{ detail[line.key] || (line.key == 'applyEnd' ? detail.longTime == 1 ? '报名长期有效' : '-' : '-' )}}{{ line.tip }}</template>
          </a-descriptions-item>
        </a-descriptions>
      </template>
      
    </div>
  </div>
</template>

<script>
export default {
  props: ['detail', 'dataList', 'tableData']
}
</script>

<style lang='scss'>
  .common_formInfo_static {
    .item {

      .top {
        padding: 11px 10px;
        margin-bottom: 10px;

        & > div {
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
          display: flex;
          align-items: center;

          & > span {
            width: 8px;
            height: 20px;
            background: #05a081;
            border-radius: 2px;
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
      .arco-descriptions {
        padding: 0 10px;
        margin-bottom: 30px;
      }

      .arco-descriptions-row {
        padding: 5px 10px;
        line-height: 22px;
        height: 32px;

        .svg-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          color: #606266;
          font-size: 14px;
        }
      }

      .arco-descriptions-item-label-block {
        width: 140px;
        margin: 10px;
        padding-bottom: 0;
        // padding-left: 10px;
      }
      .arco-descriptions-item-value-block {
        width: 430px;
        padding-bottom: 0;
        padding-right: 10px;
      }

      .arco-table {
        margin-top: 10px;

        .arco-table-cell {
          padding: 10px;
        }
      }

    }
  } 
</style>