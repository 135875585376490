<template>
  <div id="standardCommittee">
    <h3>{{ detail.councilName }}</h3>
    <p>{{ detail.synopsis }}</p>
    <CommonFormInfoStatic :detail="detail" :tableData="tableData" :dataList="dataList"></CommonFormInfoStatic>
  </div>
</template>

<script>
import CommonFormInfoStatic from '@/components/CommonFormInfoStatic.vue'
export default {
  components: {
    CommonFormInfoStatic
  },
  data() {
    return {
      detail: {},
      dataList: [
        {
          title: '基础信息',
          flex: 2,
          children: [
            { name: '标委会全称', key: 'councilName' },
            { name: '标委会简称', key: 'councilShort' },
            { name: '标委会编号', key: 'councilCode' },
            { name: '对口的全国标委会', key: 'counterpartCode' },
            { name: '当前届数', key: 'currentTerm' },
            { name: '筹建单位', key: 'constructionUnit' },
            { name: '业务指导单位', key: 'professionalUnit' },
            { name: '负责专业范围', key: 'dutyScope' },
          ]
        },
        {
          title: '秘书处信息',
          flex: 2,
          children: [
            { name: '现任秘书长', key: 'memberName' },
            { name: '秘书处所在单位', key: 'unitName' },
            { name: '联系人', key: 'contact' },
            { name: '通讯地址', key: 'address' },
            { name: '联系电话', key: 'phone' },
            { name: '邮编', key: 'postCode' },
          ]
        },
        {
          title: '本届委员',
          type: 'table',
          children: [
            { name: '序号', key: 'order', width: 50, align: 'center', type: 'index' },
            { name: '姓名', key: 'memberName', width: 200 },
            { name: '委员会职务', key: 'councilDuty', width: 200 },
            { name: '工作单位', key: 'workUnit' },
            { name: '职务/职称', key: 'unitDuty', width: 240 },
          ]
        }
      ],
      tableData: []
    }
  },
  mounted() {
    this.showPage();
    this.showTable();
  },
  methods: {
    showPage() {
      this.$API.StandardCommittee().then(res => {
        if(res.data.code == 200) {
          this.detail = { ...res.data.data }
        }
      })
    },
    showTable() {
      this.$API.StandardCommitteeTable().then(res => {
        if(res.data.code == 200) {
          this.tableData = res.data.data
        }
      })
      
    }
  }
}
</script>

<style lang='scss'>
  #standardCommittee {
    width: 1200px;
    margin: 0 auto;

    & > h3 {
      font-size: 32px;
      color: #1d2129;
      line-height: 46px;
      padding: 7px 10px;
      margin-bottom: 7px;
    }

    & > p {
      padding: 20px 10px;
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 20px;
    }
    .arco-table {
      margin: 10px 10px 0 10px;
    }
  }
</style>